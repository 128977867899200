import React from 'react';
import DarkModeIconToggle from 'app/components/Tools/DarkModeIconToggle';
import SiteLogo from './SiteLogo';

export default function Header() {
	
	return (
		<>
			<header className="fixed w-full bg-white/90 dark:bg-dark/90 backdrop-blur-sm shadow-md  top-0 left-0 z-50">
				<div className="relative flex flex-row justify-start items-center px-3 py-1 tablet:px-6 w-full">
					<div className="flex-shrink-0 mr-4">
						<SiteLogo />
					</div>
					{/* button */}
					<div className="absolute right-3">
						<div className="inline-block w-10 min-w-[2rem]">
							<DarkModeIconToggle />
						</div>
					</div>
				</div>
			</header>
			<div className="h-16 dark:bg-dark" />
		</>
	);
}