import React, { useRef, useState } from "react";
import { Icon } from "app/components";
import { FaRegClipboard } from "react-icons/fa";

interface CopyToClipboardProps {
  title?: string;
  content: string;
}
export default function CopyToClipboard({
  title,
  content,
}: CopyToClipboardProps) {
  const [copied, setCopied] = useState(false);
  const codeElement = useRef<HTMLElement>(null);

  const copyAddress = () => {
    const text = codeElement.current?.textContent;
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1000);
        })
        .catch(() => {
          setCopied(false);
        });
    }
  };

  return (
    <div className="relative">
      <pre>
        {title && `${title}:`}{" "}
        <code ref={codeElement} onClick={copyAddress}>
          {content}
        </code>
      </pre>
      <Icon
        label="Copy address"
        className="absolute top-1/2 -translate-y-2/3 right-0"
        onClick={copyAddress}
      >
        <FaRegClipboard className="h-5 w-5" />
      </Icon>
      {copied && (
        <span className="absolute right-12 top-1/2 -translate-y-2/3 text-sm font-medium text-white">
          Copied!
        </span>
      )}
    </div>
  );
}
