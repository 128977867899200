import React from 'react';
import {
  FaTelegram, FaTelegramPlane, FaTwitter
} from 'react-icons/fa';
import { DarkModeSelectToggle, Icon } from 'app/components';

export default function Footer() {
  return (
    <footer className="w-full border-t pt-8 bg-white dark:bg-dark flex justify-around items-center">
      <div className="px-2 tablet:px-8 py-4 w-full h-full flex flex-col md:flex-row justify-around items-center dark:text-gray-50">
        <div className="text-center md:text-left">
          <span>Copyright &copy; { (new Date()).getFullYear() } NFT Apocalypse - MultiChain MetaVerse NFT Platform</span>
          <div className="my-5 md:mt-0">
            <a href="https://twitter.com/NftxNft" rel="noreferrer noopener">
              <Icon label="Twitter">
                <FaTwitter className="h-5 w-5"/>
              </Icon>
            </a>
            <a href="https://t.me/NFT_xyz" rel="noreferrer noopener">
              <Icon label="Telegram Chat">
                <FaTelegram className="h-5 w-5"/>
              </Icon>
            </a>
            <a href="https://t.me/NFTx_Official" rel="noreferrer noopener">
              <Icon label="Telegram channel">
                <FaTelegramPlane className="h-5 w-5"/>
              </Icon>
            </a>
          </div>
        </div>
        <DarkModeSelectToggle />
      </div>
    </footer>
  )
}