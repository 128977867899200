import React, { useEffect, useState } from "react";
import {
	RiSunFill,
	RiMoonFill
} from 'react-icons/ri';
import useSettings from 'app/hooks/useSettings';
import { Icon } from "app/components";

export default function DarkModeToggle() {
    const {
		options: {
            darkMode
        },
		toggleDarkmode
	} = useSettings();

    const [mode, setmode] = useState<boolean>();

    useEffect(() => {
        setmode(darkMode);
    }, [darkMode]);

    return (
        <div className="relative inline-block w-full h-5" onClick={() => { toggleDarkmode() }}>
            <Icon
                label="Toggle darkmode"
                className="p-0 !ring-0 !shadow-none !text-gray-600 dark:!text-yellow-400 dark:!bg-transparent"
            >
                { mode ? <RiSunFill className="h-7 w-7" /> : <RiMoonFill className="h-7 w-7" /> }
            </Icon>
        </div>
    )

}