import React, { useEffect, useState } from "react";
import useSettings from "app/hooks/useSettings";

export default function DarkModeSelectToggle() {
  const {
    options: { darkMode },
    toggleDarkmode,
  } = useSettings();

  const [mode, setMode] = useState<"dark" | "light">();

  useEffect(() => {
    if (darkMode) {
      setMode("dark");
    } else {
      setMode("light");
    }
  }, [darkMode]);

  const switchDarkMode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const v = e.currentTarget.value as "dark" | "light";
    setMode(v);
    if (v === "dark") {
      toggleDarkmode(true);
    } else {
      toggleDarkmode(false);
    }
  };

  return (
    <div className="w-6">
      <div>Theme</div>
      <select
        className="ring-1 dark:ring-gray-50 dark:bg-dark-elevation-1"
        name="darkMode-preference"
        value={mode}
        onChange={switchDarkMode}
      >
        <option value="light">Day light</option>
        <option value="dark">Night light</option>
      </select>
    </div>
  );
}
