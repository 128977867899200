import React from "react";
// import AirDropForm from "app/components/Forms/AirDropForm";
import {
  CopyToClipboard,
  DefaultLayout,
  HeroSection,
  SolidButton,
} from "app/components";
import { RiRocket2Fill } from "react-icons/ri";

export default function HomePage() {
  return (
    <DefaultLayout className="px-3 md:px-20">
      <HeroSection />
      {/* {!isMobile && <AirDropForm />} */}
      <div className="w-full flex flex-col mx-auto lg:max-w-[calc(100%-25rem)]">
        <div className="prose prose-lg max-w-full dark:text-gray-50 my-8 inline-block">
          <h2 className="dark:text-gray-50">Introduction</h2>
          <div>
            <img
              src="/images/backgrounds/nftx-introduction-meme.jpg"
              alt="NFTx introduction meme"
              className="w-full"
            />
            <p>
              The NFT Apocalypse Dao would connect augmented reality(AR/VR) with
              Decentralized Finance (DeFi) and Non-Fungible Tokens (NFT's)
              across multiple blockchains while rewarding holders and creators.
            </p>
            <p>
              NFT Apocalypse is the first MultiChain NFT MetaVerse platform
              adding value to both holders and digital creators. Don't miss!!!
            </p>
          </div>
        </div>
        <div className="relative prose md:prose-lg max-w-full dark:text-gray-50 mb-8">
          <h2 className="dark:text-gray-50">The NFTx Utility Token</h2>
          <img
            src="/images/backgrounds/nftx-web-meme-2.jpg"
            alt="The NFTx utility token meme"
            className="w-full"
          />
          <p>
            NFT Apocalypse (NFTx) is well positioned to dominate the NFT
            MetaVerse as a utility token, and the first community-focused
            multi-chain project on 4 unique blockchains with the same contract
            address.
          </p>
          <p>
            NFTx is the governance token for NFT Apocalypse and can only be
            mined via LP mining (in Farms) and NFTx staking (in STUDIO pools).
            For fairness considerations, only 1,200,000,000,000 NFTx will be
            initally minted, hence all investors get an equal chance of getting
            NFTx. 0.05% of all transaction fee in NFTx Galleria (our own NFT
            marketplace) will be collected in the NFTx treasury account, which
            will all be used to buy back NFTx and burn.
          </p>
        </div>
        <div className="relative prose md:prose-lg max-w-full dark:text-gray-50 mb-8">
          <h2 className="dark:text-gray-50">Tokenomics</h2>
          <ul>
            <li>Max. Total Supply - 10,000,000,000,000 NFTx</li>
            <li>Genesis Mint: 1,200,000,000,000 NFTx</li>
            <li>LP Farming: 1,000,000,000,000 NFTx</li>
            <li>Airdrop: 14,000,000,000 NFTx</li>
            <li>Distribution via LP Mining and Staking</li>
          </ul>
        </div>
        <div className="prose md:prose-lg max-w-full dark:text-gray-50">
          <h2 className="dark:text-gray-50">Smart Contract Address</h2>
          <p>
            We are a revolutionary project already existing on four(4)
            Blockchains with the same smart contract address.
          </p>
          <CopyToClipboard
            content="0xda5dea132f9c30f2f6b513266795fec16426c0c6"
            title="Address"
          />
        </div>
        <div className="prose md:prose-lg max-w-full mb-4 lg:max-w-[calc(100%-25rem)]">
          <a
            href="https://bscscan.com/token/0xda5dea132f9c30f2f6b513266795fec16426c0c6"
            rel="noferrer noopner"
          >
            <SolidButton
              label="View on Binance Smart Chain"
              className="my-2 mx-2 bg-yellow-600 hover:bg-yellow-700"
            />
          </a>

          <a
            href="https://hecoinfo.com/token/0xda5dea132f9c30f2f6b513266795fec16426c0c6"
            rel="noferrer noopner"
          >
            <SolidButton
              label="View on Huobi Blockchain"
              className="my-2 mx-2"
            />
          </a>

          <a
            href="https://blockscout.com/xdai/mainnet/token/0xda5deA132F9C30F2F6B513266795Fec16426C0C6"
            rel="noferrer noopner"
          >
            <SolidButton label="View on xDai MainNet" className="my-2 mx-2" />
          </a>

          <a
            href="https://ftmscan.com/token/0xda5dea132f9c30f2f6b513266795fec16426c0c6"
            rel="noferrer noopner"
          >
            <SolidButton
              label="View on Fantom Opera(FTM)"
              className="my-2 mx-2"
            />
          </a>
        </div>
        <div className="prose md:prose-lg mb-4 max-w-full dark:text-gray-50">
          <h2 className="dark:text-gray-50">Airdrop</h2>
          <img
            src="/images/backgrounds/nftx-airdrop-meme.jpg"
            alt="NFTx airdrop meme"
            className="w-full"
          />
          <p>
            Round 1 closed. Please stay tuned on our official handles for more
            information.
          </p>
        </div>
        {/* {isMobile && <AirDropForm />} */}
        <div className="prose md:prose-lg mb-4 max-w-full dark:text-gray-50">
          <h2 className="dark:text-gray-50">Listing</h2>
          <p>
            NFT Apocalypse (NFTx) Token is listed on{" "}
            <a
              href="https://spookysway.finance"
              rel="noferrer noopner"
              className="dark:text-blue-200"
            >
              https://spookysway.finance
            </a>
            .
          </p>
        </div>
        <div className="prose md:prose-lg mb-4 max-w-full dark:text-gray-50">
          <h2 className="dark:text-gray-50">How To Buy/Sell NFTx?</h2>
          <ol>
            <li>Go to your wallet (Trust wallet, metamask etc)</li>
            <li>
              Copy the link below and paste in your Dapp
              <CopyToClipboard
                content="https://spookysway.finance"
              />
            </li>
            <li>Click on swap</li>
            <li>
              Copy NFTx Contract Address
              <CopyToClipboard
                content="0xda5dea132f9c30f2f6b513266795fec16426c0c6"
              />
            </li>
            <li>
              Make sure you are on the Fantom network and have FTM in your
              wallet
            </li>
            <li>Paste NFTx CA and add Token</li>
            <li>
              Swap your FTM to NFTx <RiRocket2Fill className="inline" />{" "}
              <RiRocket2Fill className="inline" />{" "}
              <RiRocket2Fill className="inline" />
            </li>
          </ol>
        </div>
        <div className="prose md:prose-lg mb-4 max-w-full dark:text-gray-50">
          <h2 className="dark:text-gray-50">Upcomming</h2>
          <ul>
            <li>NFTx Governanace Staking</li>
            <li>NFTx Galleria</li>
          </ul>
          <p>
            With NFTx, you'll be able to send (buy or sell) your NFTs accross
            different blockchains from one platform. Stake NFTx to earn more
            NFTx. NFTx is the governance token for the NFT Apocalypse Metaverse,
            all holders have a right in determining how network resources are
            allocated.
          </p>
        </div>
      </div>
    </DefaultLayout>
  );
}
