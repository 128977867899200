import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useEffect,
} from "react";
import { debounce } from "lodash";
import { SettingsContextType } from "app/types";
import { handleDarkMode } from "app/utils";

const defaultSettings: SettingsContextType = {
  options: {
    darkMode: handleDarkMode(),
    isMobile: true,
  },
  toggleDarkmode: (value?: boolean) => {},
  updateSettings: () => {},
};

interface ProviderProps {
  children: React.ReactNode;
}

const SettingsContext = createContext<SettingsContextType>(defaultSettings);

const screens = {
  tablet: "640px",
  laptop: "1024px",
  desktop: "1280px",
};

export function SettingsProvider({ children }: ProviderProps) {
  const { options } = useContext(SettingsContext);
  const [currentSettings, setCurrentSettings] = useState<
    SettingsContextType["options"]
  >(
    options || {
      ...defaultSettings.options,
    }
  );

  const mobileWidth = useRef<number>(parseInt(screens.laptop));
  const handleResize = debounce(() => {
    const width = window.innerWidth;
    if (width <= mobileWidth.current) {
      setCurrentSettings((prev) => ({ ...prev, isMobile: true }));
    } else {
      setCurrentSettings((prev) => ({ ...prev, isMobile: false }));
    }
  }, 500);
  
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const changeDarkmode = (value?: boolean) => {
    if (value === undefined) {
      setCurrentSettings((prev) => {
        handleDarkMode(!prev.darkMode);
        return { ...prev, darkMode: !prev.darkMode };
      });
    } else {
      setCurrentSettings((prev) => ({ ...prev, darkMode: value }));
      handleDarkMode(value);
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        options: currentSettings,
        updateSettings: setCurrentSettings,
        toggleDarkmode: changeDarkmode,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsContext;
