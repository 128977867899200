import React from 'react';

export default function HeroHeader() {
	return (
		<section className="bg-white flex flex-col items-center dark:bg-dark py-24" style={{backgroundImage: 'url("/images/nftx-cover.png")'}}>
			<div className="md:w-2/3 ml-5 bg-white py-8 px-4 dark:bg-dark dark:text-gray-50 text-3xl md:text-4xl font-semibold">
                <p>The first MultiChain MetaVerse NFT platform rewarding both holders and digital creators</p>
            </div>
		</section>
	);
}