import React from 'react';
import { SolidButton } from 'app/components';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="fixed flex justify-center items-center top-0 bottom-0 w-full px-4 bg-white dark:bg-dark">
            <div className="flex flex-col justify-center items-center max-w-md text-center dark:text-gray-50">
                <img
                    className="mb-8 w-full"
                    src="/images/illustrations/404.svg"
                    alt=""
                />
                Hmm, we could not find the page you were searching for, but we can take you to a page that exists.
                <Link to="/">
                    <SolidButton
                        className="!uppercase block my-8 dark:text-blue-200"
                        variant="outlined"
                        color="primary"
                        label="Back to home"
                    >
                        Back to Home
                    </SolidButton>
                </Link>
            </div>
        </div>
    )
}

export default NotFound
