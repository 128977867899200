import React from 'react';
import classnames from 'classnames';
import { ButtonProps } from 'app/types';

export default function SolidButton({
	className,
	label,
	active,
	variant = "primary",
	children,
	...props
}: ButtonProps) {

	let variantClass = "";

	switch (variant) {
		case ( "primary"):
			variantClass = "bg-blue-600 hover:bg-blue-700 dark:bg-blue-200 text-gray-100 dark:text-black focus:bg-blue-700 focus:dark:bg-secondary-400 py-2 px-4 rounded";
		break;

		case ("secondary"):
			variantClass = "bg-secondary-800 text-white focus:bg-secondary-600";
		break;

		case ("outlined"):
			variantClass = "bg-transparent text-blue-600 border-2 border-gray-700 p-4";
		break;

		case "floating":
			variantClass = "fixed bg-red-700 font-thin text-sm text-white shadow-md p-2 right-4 rounded-full z-50 -translate-y-8 transition-all";
			break;

		default:
			throw(new Error("invalid variant type supplied"));
	}

	return (
		<button
			className={classnames('btn', variantClass, className)}
			{...props}
			title={label}
		>
			{children || label}
		</button>
	)
}