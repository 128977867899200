import React from 'react';
import './assets/css/styles.css';
import HomePage from 'app/views/Home';
import NotFound from './views/NotFound';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { SettingsProvider } from 'app/contexts/SettingsContext';

function App() {
  return (
    <SettingsProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </SettingsProvider>
  );
}

export default App;
