import React from 'react';
import Header from 'app/components/Layouts/shared/Header';
import Footer from 'app/components/Layouts/shared/Footer';

export default function DefaultLayout({ children, className }: { children: React.ReactNode, className: string | undefined }) {
	return (
        <>
            <Header />
                <main className={`relative flex flex-col items-center w-full dark:bg-dark ${className} pb-8`}>
                    {children}
                </main>
            <Footer />
        </>
	)
}