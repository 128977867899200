import React from "react";

export default function SiteLogo() {
    return (
        <div className="w-auto flex justify-start items-center dark:text-gray-50">
            <div className="w-14 h-14 relative mr-2">
                <img
                    src="/images/nftx-apocalypse.png"
                    title="Vision"
                    className="rounded-full w-full h-full"
                    alt="Site logo"
                />
            </div>
            <span className="text-lg font-bold">NFT Apocalypse</span>
        </div>
    );
}