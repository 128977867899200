import React from 'react';
import classnames from 'classnames';
import { ButtonProps } from 'app/types';

type IconProps = {
  children: React.ReactNode;
}

export default function Icon({
  className,
  children,
  label,
  variant = "primary",
  ...props
}: IconProps & ButtonProps) {
  return (
    <button
      className={classnames(
        "m-2 inline-block text-blue-600 border-0 outline-none dark:text-blue-500 bg-white dark:bg-dark-elevation-1",
        "rounded-full p-2 font-medium shadow-md ring-1 ring-gray-100 dark:ring-0 ring-offset-0 active:hover:scale-110",
        className
      )}
      {...props}
      title={label}
      aria-label={`${label} icon`}
    >
      {children}
    </button>
  )
}